import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useFetch, usePost, usePut, useDelete } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import { useTheme } from 'styled-components'

// Components
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import { Form, InputGroup } from 'react-bootstrap'
import CardLoading from '../../components/component-items/loading-popover'
import { TableRow } from '../orders/styles'
import { TableCell } from '../../components/component-items/datatable'
import Checkbox from '../../components/component-items/checkbox'
import { Pages } from '../../components/component-items/pager'
import { SearchBox } from '../../components/component-items/search'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'

export const AddRuleModal = ({
  editRule,
  open,
  warehouses,
  setOpen,
}: {
  editRule: any
  open: boolean
  setOpen: (open: boolean) => void
  warehouses: any
}) => {
  const node = useRef<HTMLFormElement | null>(null)
  const {
    state: { csrf },
  } = globalState()
  const [validated, setValidated] = useState(false)
  const defaultRule = {
    id: null,
    warehouse: '',
    skuMatch: '1',
    quantityType: 'on_hand',
    quantityTypeDisplay: 'On Hand',
    skus: '',
    threshold: 0,
    emailAddresses: '',
    sendClearedNotification: false,
    enabled: false,
  }
  const [rule, setRule] = useState(defaultRule)

  useEffect(() => {
    if (Object.keys(editRule).length === 0 && editRule.constructor === Object) {
      setRule(defaultRule)
    } else {
      if (editRule.ruleType == 'All Skus') {
        setRule({ ...editRule, skuMatch: '1' })
        setRule({ ...editRule, skus: '' })
      } else if (editRule.ruleType == 'Exact Skus') {
        setRule({ ...editRule, skuMatch: '2' })
      } else {
        setRule({ ...editRule, skuMatch: '3' })
      }

      if (editRule.quantityTypeDisplay == 'Quantity Available') {
        setRule({ ...editRule, quantityType: 'quantity_available' })
      } else if (editRule.quantityTypeDisplay == 'On Hand') {
        setRule({ ...editRule, quantityType: 'on_hand' })
      }
    }
  }, [editRule])

  const handleSubmit = (e: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    // Updating a rule
    if (rule.id) {
      usePut(`/api/inventory/stock-alert/rules/${rule.id}/`, rule, csrf)
    } else {
      // Creating a rule
      usePost('/api/inventory/stock-alert/rules/', rule, csrf)
    }
  }

  return (
    <GenericModal
      heading={rule.id ? 'Update Stock Alert Rule' : 'Create Stock Alert Rule'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{rule.id ? 'Update' : 'Create'}</GlobalStyles.Button>}
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '2em' }}>
          <Form.Label>Warehouse</Form.Label>
          <Form.Select
            name="warehouse"
            value={rule.warehouse}
            onChange={(e: any) => setRule({ ...rule, warehouse: e.target.value })}
          >
            <option value={''}>{'All'}</option>

            {warehouses?.map((w: any, id: number) => (
              <option value={w.id} key={id}>
                {w.name}
              </option>
            ))}
          </Form.Select>
          <Form.Label style={{ marginTop: '1em' }}>Quantity Type</Form.Label>
          <Form.Select value={rule.quantityType} onChange={(e) => setRule({ ...rule, quantityType: e.target.value })}>
            <option value="on_hand">On Hand</option>
            <option value="quantity_available">Quantity Available</option>
          </Form.Select>

          <Form.Label style={{ marginTop: '1em' }}>Rule Type</Form.Label>
          <Form.Select
            value={rule.skuMatch}
            onChange={(e) =>
              setRule({ ...rule, skuMatch: e.target.value, skus: e.target.value === '1' ? '' : rule.skus })
            }
          >
            <option value="1">All SKUs</option>
            <option value="2">Exact SKUs</option>
            <option value="3">Partial Match SKUs</option>
          </Form.Select>
          <Form.Label style={{ marginTop: '1em' }}>SKU List</Form.Label>
          <Form.Control
            style={{ resize: 'none' }}
            as="textarea"
            rows={7}
            name="skus"
            placeholder={Number(rule.skuMatch) === 1 ? '' : 'sku_1\nsku_2\nsku_3'}
            value={rule.skus}
            onChange={(e) => setRule({ ...rule, skus: e.target.value })}
            disabled={Number(rule.skuMatch) === 1}
          />
          <p style={{ fontSize: '.9em', marginTop: '1em' }}>*Enter one SKU or partial SKU per line.</p>
          <Form.Label style={{ marginTop: '1em' }}>Threshold</Form.Label>
          <Form.Control
            type="number"
            value={rule.threshold}
            min="0"
            onChange={(e) => setRule({ ...rule, threshold: Number(e.target.value) })}
          />
          <p style={{ fontSize: '.9em', marginTop: '1em' }}>
            *When the shelf quantity reaches this level, or lower, we will notify you.
          </p>
          <Form.Label style={{ marginTop: '1em' }}>Email Addresses</Form.Label>
          <Form.Control
            style={{ resize: 'none' }}
            as="textarea"
            rows={7}
            name="emailAddresses"
            placeholder="email_1@gmail.com&#10;email_2@gmail.com&#10;email_3@gmail.com"
            value={rule.emailAddresses}
            onChange={(e) => setRule({ ...rule, emailAddresses: e.target.value })}
          />
          <p style={{ fontSize: '.9em', marginTop: '1em' }}>
            *Enter one email address per line and they will be notified of stock alerts. Check box below to activate
            this feature.
          </p>
          <InputGroup style={{ marginTop: '.8em' }}>
            <Checkbox
              selected={rule.sendClearedNotification}
              setSelected={() => setRule({ ...rule, sendClearedNotification: !rule.sendClearedNotification })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Send notication when alert has cleared </p>
          </InputGroup>
          <InputGroup>
            <Checkbox selected={rule.enabled} setSelected={() => setRule({ ...rule, enabled: !rule.enabled })} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Enable Stock Alert Rule</p>
          </InputGroup>
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

const ActiveAlerts = () => {
  const theme = useTheme() as any
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const {
    state: { csrf },
  } = globalState()

  const url = `/api/inventory/stock-alert/alert_list/?page=${page}&q=${encodeURIComponent(search)}`
  const { response: resp, loaded, error, placeholder } = useFetch(url, {})
  const { count, results }: any = loaded && resp ? resp : {}
  const [alerts, setAlerts] = useState<any[]>([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  useEffect(() => {
    if (loaded && results && totalPages === 1) {
      setTotalPages(Math.ceil(count / results.length))
    }
  }, [loaded])

  useEffect(() => {
    setAlerts(results || [])
    setTotal(count || 0)
  }, [results])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Alerts<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text center">Rule</span>
              </th>
              <th>
                <span className="text center">Warehouse</span>
              </th>
              <th>
                <span className="text center">QTY Type</span>
              </th>
              <th>
                <span className="text center">Type</span>
              </th>
              <th>
                <span className="text center">sku</span>
              </th>
              <th>
                <span className="text center">Threshold</span>
              </th>
              <th>
                <span className="text center">On hand</span>
              </th>
              <th>
                <span className="text center">QTY Available</span>
              </th>
              <th>
                <span className="text center">Created</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {alerts?.map((alert: any, kId: number) => (
              <TableRow key={kId}>
                <TableCell center input={alert.rule} />
                <TableCell center input={alert.warehouseName} />
                <TableCell center input={alert.ruleDetails.quantityTypeDisplay} />
                <TableCell center input={alert.ruleDetails.ruleType} />
                <TableCell
                  center
                  input={
                    <Link target="_blank" to={`/product/variant/${alert.variantId}/`}>
                      {alert.sku}
                    </Link>
                  }
                ></TableCell>
                <TableCell center input={alert.threshold} />
                <TableCell center input={alert.currentShelf} />
                <TableCell center input={alert.quantityAvailable} />
                <TableCell center input={new Date(alert.created).toLocaleString()} />
                <TableCell
                  center
                  input={
                    <button
                      style={{ color: theme?.colors.dodgerBlue }}
                      onClick={() => {
                        useDelete(`/api/inventory/stock-alert/${alert.id}/`, csrf)
                      }}
                    >
                      Dismiss
                    </button>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        {totalPages > 1 && (
          <>
            <br />
            <Pages page={page} setPage={(p: any) => setPage(p)} total={totalPages} show={5} />
            <br />
          </>
        )}
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}

const StockAlertRules = () => {
  const {
    state: { csrf, activeWarehouses },
  } = globalState()
  const theme = useTheme() as any
  const [open, setOpen] = useState(false)
  const [editRule, setEditRule] = useState({})
  const [rules, setRules] = useState<any[]>([])

  const { response: resp, loaded, error, placeholder } = useFetch(`/api/inventory/stock-alert/rules/rule_list/`, {})
  const { count: total, results }: any = loaded && resp ? resp : {}

  useEffect(() => {
    setRules(results || [])
  }, [results])

  const deleteRule = (index: string | number, ruleId: string) => async () => {
    await useDelete(`/api/inventory/stock-alert/rules/${ruleId}/`, csrf, false, true, true, (res: any) => {
      if (res.ok) {
        window.location.reload()
      }
    })
  }

  const changeRule = (ruleId: string | number, action: string) => () => {
    const newState = rules?.map((rule) => {
      if (rule.id === ruleId) {
        if (action === 'send_email') {
          return { ...rule, sendClearedNotification: !rule.sendClearedNotification }
        }
        return { ...rule, enabled: !rule.enabled }
      }
      return rule
    })
    setRules(newState)
    usePut(`/api/inventory/stock-alert/rules/${ruleId}/toggle_${action}/`, {}, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Rules<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            onClick={() => {
              setEditRule({})
              setOpen(true)
            }}
          >
            Add Rule
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text center">Rule</span>
              </th>
              <th>
                <span className="text center">Warehouse</span>
              </th>
              <th>
                <span className="text center">QTY Type</span>
              </th>
              <th>
                <span className="text center">Type</span>
              </th>
              <th>
                <span className="text center">SKUs</span>
              </th>
              <th>
                <span className="text center">Threshold</span>
              </th>
              <th>
                <span className="text center">Emails</span>
              </th>
              <th>
                <GlobalStyles.Tooltip data-title="Email will be sent to all emails attached to this rule whenver a threshold is cleared and enabled setting is on">
                  <span className="text center">
                    Notify
                    <FontAwesomeIcon
                      style={{ color: theme?.colors.freshEggplant, marginLeft: '5px' }}
                      icon={faQuestionCircle}
                    />
                  </span>
                </GlobalStyles.Tooltip>
              </th>
              <th>
                <span className="text center">Enable</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {rules?.map((rule: any, kId: number) => (
              <TableRow key={kId}>
                <TableCell center input={rule.id} />
                <TableCell center input={rule.warehouse ? rule.warehouseName : 'All'} />
                <TableCell center input={rule.quantityTypeDisplay} />
                <TableCell center input={rule.ruleType} />
                <TableCell
                  center
                  input={
                    rule.skus
                      ? rule.skuList.map((sku: string) => (
                          <>
                            {sku}
                            <br />
                          </>
                        ))
                      : '-'
                  }
                />
                <TableCell center input={rule.threshold} />
                <TableCell
                  center
                  input={rule.emailList.map((email: string) => (
                    <>
                      {email}
                      <br />
                    </>
                  ))}
                />

                <TableCell
                  center
                  input={
                    <Form.Check
                      type="switch"
                      id={'email-' + rule.id}
                      defaultChecked={rule.sendClearedNotification}
                      onChange={changeRule(rule.id, 'send_email')}
                    />
                  }
                />
                <TableCell
                  center
                  input={
                    <Form.Check
                      type="switch"
                      id={'rule-' + rule.id}
                      defaultChecked={rule.enabled}
                      onChange={changeRule(rule.id, 'rule')}
                    />
                  }
                />
                <TableCell
                  center
                  input={
                    <>
                      <button
                        onClick={() => {
                          setEditRule(rule)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={deleteRule(kId, rule.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      <AddRuleModal editRule={editRule} open={open} setOpen={setOpen} warehouses={activeWarehouses} />
    </GlobalStyles.FullPageCard>
  )
}

export const StockAlertsPage = () => {
  return (
    <>
      <StockAlertRules />
      <ActiveAlerts />
    </>
  )
}
