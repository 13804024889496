import React, { useEffect, useRef, useState } from 'react'
import { notify, useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import { TableCell } from '../../../components/component-items/datatable'

// Plugins
import styled from 'styled-components'
import { useParams } from 'react-router'
import CardLoading from '../../../components/component-items/loading-popover'
import { faPrint } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LargeButton } from '../../../components/component-items/buttons'

type SSCCBarcodeModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

const Tooltip = styled(GlobalStyles.Tooltip)`
  &:before {
    width: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

export const SSCCBarcodeModal = ({ open, setOpen }: SSCCBarcodeModalProps) => {
  const { id: order } = useParams<{ id: string }>()
  const {
    state: { csrf, userIsWarehouse },
  } = globalState()

  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const [shipments, setShipments] = useState<any>([])

  const url = open ? `/api/order/${order}/shipments/` : ''
  const { response: resp, loaded, error, placeholder }: any = useFetch(url, {})

  useEffect(() => {
    if (loaded) {
      setShipments(resp?.shipments || [])
    }
  }, [loaded])

  const handleGenerateBarcodes = async () => {
    const resp = await usePost(`/api/order/${order}/edi_generate_sscc_barcodes/`, {}, csrf, false)
    if (resp.success) {
      notify({
        type: 'success',
        title: 'SSCC Barcodes',
        message: 'SSCC Barcodes have been successfully generated for this order.',
      })
      setOpen(false)
      window.open(`/api/order/${order}/print_gs1_labels/`, '_blank')
    }
  }

  const printBarcode = (id: number) => {
    window.open(`/api/shipment/${id}/print_sscc_barcode/`, '_blank')
  }

  const printBarcodes = () => {
    window.open(`/api/order/${order}/print_gs1_labels/`, '_blank')
  }

  return (
    <GenericModal
      heading={'Edit SSCC Barcodes'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <>
          <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={handleGenerateBarcodes}>
            Generate Barcodes
          </GlobalStyles.Button>
          {userIsWarehouse && (
            <GlobalStyles.Button className="royal" style={{ minWidth: 125 }} onClick={() => printBarcodes()}>
              Print Barcodes
            </GlobalStyles.Button>
          )}
        </>
      }
    >
      <Form noValidate ref={node} validated={validated} style={{ minHeight: 400 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text center">Shipment</span>
              </th>
              <th>
                <span className="text center">Order Items</span>
              </th>
              <th>
                <span className="text center">Dims</span>
              </th>
              <th>
                <span className="text center">SSCC Barcode</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {shipments?.map((shipment: any, id: number) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell input={shipment.shipmentNumber} center></TableCell>
                <TableCell>
                  {shipment.orderItems?.products?.length && (
                    <div
                      style={{
                        maxHeight: 200,
                        overflow: 'scroll',
                        boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                      }}
                    >
                      <GlobalStyles.DataTable>
                        <tbody>
                          {shipment.orderItems?.products?.map((item: any, id: number) => (
                            <TableRow key={id}>
                              <TableCell input={item.sku}>
                                <div>{item.desc}</div>
                              </TableCell>
                              <TableCell input={item.qty} center />
                            </TableRow>
                          ))}
                        </tbody>
                      </GlobalStyles.DataTable>
                    </div>
                  )}
                </TableCell>
                <TableCell
                  center
                  input={`${shipment.dims?.length} x ${shipment.dims?.width} x ${shipment.dims?.height} (${shipment.dims?.weight} oz)`}
                ></TableCell>
                <TableCell>
                  <Form.Control
                    type="text"
                    placeholder="SSCC Barcode"
                    value={shipment.ssccBarcode}
                    onChange={(e) => {
                      setShipments((shipments: any) => {
                        return shipments.map((s: any) => {
                          if (s.shipmentNumber === shipment.shipmentNumber) {
                            s.ssccBarcode = e.target.value
                          }
                          return s
                        })
                      })
                    }}
                  />
                </TableCell>
                <TableCell
                  center
                  input={
                    <div className="dropdown__container" style={{ display: 'flex', justifyContent: 'center' }}>
                      <>
                        <Tooltip className="subheader__button button" data-title="Print Label">
                          <LargeButton type="button" onClick={() => printBarcode(shipment.id)}>
                            <FontAwesomeIcon icon={faPrint} />
                          </LargeButton>
                        </Tooltip>
                      </>
                    </div>
                  }
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </Form>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GenericModal>
  )
}
