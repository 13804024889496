import React, { useState, useEffect } from 'react'
import { Tag } from './styles'
import { useFetch, usePost, notify, useDelete } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'
import { format } from 'date-fns'

// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import GlobalStyles from '../../../../components/component-items/styles'
import ButtonDropdown from '../../../../components/component-items/button-dropdown'
import { TableRow } from '../../../orders/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { HoverNotes } from '../../../../components/component-items/hover-notes'
import { SMS } from './sms'
import { TOTP } from './totp'
import { LargeButton } from '../../../../components/component-items/buttons'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faEye } from '@fortawesome/pro-duotone-svg-icons'
import { faInfoCircle, faTrash, faPlusHexagon } from '@fortawesome/pro-light-svg-icons'

import {
  TwoFactorAuthenticationDescriptions,
  TwoFactorAuthenticationIcons,
  TwoFactorAuthenticationDisplays,
} from '../../../../enums'

export const TwoFactorOptions = () => {
  const [primaryMethod, setPrimaryMethod] = useState<any>({})
  const [sort, setSort] = useState('')
  const [smsOpen, setSmsOpen] = useState(false)
  const [totpOpen, setTotpOpen] = useState(false)
  const [sms, setSms] = useState<any>({})
  const [totp, setTotp] = useState<any>({})
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('')
  const [totpEnabled, setTotpEnabled] = useState(false)
  const sortOptions: any = {
    'SMS/Text Message': 'sms',
    'One-Time Password': 'totp',
    // "Security Keys (YubiKey)": "yubikey",
  }
  const {
    state: { csrf, userID },
  } = globalState()

  const url = `/api/core/users/${userID}/2fa/?primaryMethod=${primaryMethod.mfa_type}?currentPhoneNumber=${currentPhoneNumber}?totpEnabled=${totpEnabled}`

  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})
  const { count: total, results }: any = loaded && resp ? resp : {}
  console.log(results)

  useEffect(() => {
    if (loaded && total > 0) {
      const pm = results.find((result: any) => result.is_primary)
      const sms = results.find((result: any) => result.mfa_type === 'sms')
      const totp = results.find((result: any) => result.mfa_type === 'totp')
      if (pm) {
        setPrimaryMethod(pm)
        setSort(Object.keys(sortOptions).find((key) => sortOptions[key] === pm.mfa_type) || '')
      }
      if (sms) {
        setCurrentPhoneNumber(sms.phone_number)
        setSms(sms)
      }
      if (totp) {
        setTotpEnabled(totp.totp_enabled)
        setTotp(totp)
      }
    }
  }, [loaded])

  const updatePrimaryMethod = async (new_method: any) => {
    const resp: any = await usePost(`/api/core/users/${userID}/2fa/${new_method.id}/set_primary/`, {}, csrf, false)
    if (resp.id) {
      setPrimaryMethod(resp)
      setSort(TwoFactorAuthenticationDisplays[resp.mfa_type])
    }
  }

  useEffect(() => {
    if (sort && primaryMethod && TwoFactorAuthenticationDisplays[primaryMethod.mfa_type] !== sort) {
      const new_pm = results.find((result: any) => TwoFactorAuthenticationDisplays[result.mfa_type] === sort)
      if (new_pm === undefined || new_pm.configured === false) {
        notify({
          type: 'warning',
          message: `You must configure ${sort} in order to set as your primary method`,
          autoClose: false,
        })
        setSort(TwoFactorAuthenticationDisplays[primaryMethod.mfa_type])
        return
      }
      updatePrimaryMethod(new_pm)
    }
  }, [sort])

  return (
    <GlobalStyles.FullPageCard style={{ minHeight: 500 }}>
      <GlobalStyles.CardHeader style={{ marginTop: 10 }}>
        <GlobalStyles.CardTitle>
          <h3>Two-factor Authentication</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar style={{ padding: 15 }}>
          {results?.filter((result: any) => result.configured)?.length > 0 && (
            <>
              <HoverNotes
                description="Set your primary method to use for two-factor authentication when signing into ShippingTree."
                title="Primary 2FA Method:"
              />
              <ButtonDropdown
                sort={sort}
                setSort={setSort}
                options={Object.keys(sortOptions)}
                height={35}
                width={200}
              />
            </>
          )}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <th>
              <span className="text" style={{ marginLeft: '10px' }}>
                Two-factor Method
              </span>
            </th>
            <th>
              <span className="text center">Added On</span>
            </th>
            <th>
              <span className="text center">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRow>
            <TableCell
              input={
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                  <FontAwesomeIcon
                    size="2x"
                    icon={TwoFactorAuthenticationIcons['sms']}
                    style={{ marginRight: '10px', marginBottom: '14px' }}
                  />
                  <h5>
                    {TwoFactorAuthenticationDisplays['sms']}
                    {sms.configured && <Tag className="configured"> Configured </Tag>}
                    {sms.is_primary && <Tag className="primary"> Primary </Tag>}
                    <br />
                    <span style={{ fontSize: '.75em' }}>{TwoFactorAuthenticationDescriptions['sms']}</span>
                  </h5>
                </div>
              }
            />
            <TableCell center input={sms.configured && format(new Date(sms?.configured_date), "MMM d 'at' h:mm a")} />
            <TableCell
              center
              input={
                <div className="dropdown__container">
                  <LargeButton onClick={() => setSmsOpen(true)}>
                    <GlobalStyles.Tooltip data-title={sms.configured ? 'View Configuration' : 'Add Method'}>
                      {sms.configured ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faPlusHexagon} />}
                    </GlobalStyles.Tooltip>
                  </LargeButton>
                  {sms.configured && (
                    <LargeButton onClick={() => useDelete(`/api/core/users/${sms?.user_id}/2fa/${sms?.id}/`, csrf)}>
                      <GlobalStyles.Tooltip data-title={'Reset Method'}>
                        <FontAwesomeIcon icon={faTrash} />
                      </GlobalStyles.Tooltip>
                    </LargeButton>
                  )}
                </div>
              }
            />
          </TableRow>
          <TableRow>
            <TableCell
              input={
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                  <FontAwesomeIcon
                    size="2x"
                    icon={TwoFactorAuthenticationIcons['totp']}
                    style={{ marginRight: '10px', marginBottom: '14px' }}
                  />
                  <h5>
                    {TwoFactorAuthenticationDisplays['totp']}
                    {Object.keys(primaryMethod).length === 0 && <Tag className="recommended"> Recommended </Tag>}
                    {totp.configured && <Tag className="configured"> Configured </Tag>}
                    {totp.is_primary && <Tag className="primary"> Primary </Tag>}
                    <br />
                    <span style={{ fontSize: '.75em' }}>{TwoFactorAuthenticationDescriptions['totp']}</span>
                  </h5>
                </div>
              }
            />
            <TableCell center input={totp.configured && format(new Date(totp?.configured_date), "MMM d 'at' h:mm a")} />
            <TableCell
              center
              input={
                <div className="dropdown__container">
                  {totp.configured ? (
                    <>
                      <LargeButton
                        onClick={() => window.open('https://help.shippingtree.co/article/71-using-2fa-apps', '_blank')}
                      >
                        <GlobalStyles.Tooltip data-title={'Method guide'}>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </GlobalStyles.Tooltip>
                      </LargeButton>
                      <LargeButton onClick={() => useDelete(`/api/core/users/${totp?.user_id}/2fa/${totp?.id}/`, csrf)}>
                        <GlobalStyles.Tooltip data-title={'Reset Method'}>
                          <FontAwesomeIcon icon={faTrash} />
                        </GlobalStyles.Tooltip>
                      </LargeButton>
                    </>
                  ) : (
                    <LargeButton onClick={() => setTotpOpen(true)}>
                      <GlobalStyles.Tooltip data-title={'Add Method'}>
                        <FontAwesomeIcon icon={faPlusHexagon} />
                      </GlobalStyles.Tooltip>
                    </LargeButton>
                  )}
                </div>
              }
            />
          </TableRow>
          <SMS
            smsOpen={smsOpen}
            setSmsOpen={setSmsOpen}
            method={sms}
            currentPhoneNumber={currentPhoneNumber}
            setCurrentPhoneNumber={setCurrentPhoneNumber}
          />
          <TOTP setTotpEnabled={setTotpEnabled} totpOpen={totpOpen} setTotpOpen={setTotpOpen} />
        </tbody>
      </GlobalStyles.DataTable>
      {!loaded && <CardLoading text={placeholder} error={error} />}
    </GlobalStyles.FullPageCard>
  )
}
